<template>
  <v-expansion-panels
    v-model="panel"
    class="filters-container"
    flat
    multiple
  >
    <v-expansion-panel>
      <v-expansion-panel-header class="filters-header px-0">Filtros</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-text-field
          v-model="form.name"
          solo
          flat
          outlined
          dense
          clear-icon="mdi-close-circle"
          label="Nombre ..."
          clearable
        >
        </v-text-field>
        <v-row no-gutters>
          <v-col cols="3">
            <div>
              <label class="filter-label">Fechas</label>
            </div>
          </v-col>
          <v-col cols="9">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  label="Seleccione"
                  readonly
                  dense
                  clearable
                  clear-icon="mdi-close-circle"
                  solo
                  flat
                  outlined
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.dates"
                range
                no-title
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(form.dates)"
                >
                  Aceptar
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="3">
            <div>
              <label class="filter-label">Tipo</label>
            </div>
          </v-col>
          <v-col cols="9">
            <v-select
              :items="templateTypes"
              item-text="name"
              item-value="id"
              label="Tipo"
              solo
              outlined
              clearable
              dense
              clear-icon="mdi-close-circle"
              v-model="form.typeTemplate"
              flat
            ></v-select>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="countryFilter">
          <v-col cols="3">
            <div>
              <label class="filter-label">País</label>
            </div>
          </v-col>
          <v-col cols="9">
            <v-select
              :items="countries"
              item-text="name"
              item-value="country_code"
              label="País"
              solo
              outlined
              clearable
              dense
              clear-icon="mdi-close-circle"
              v-model="form.country"
              flat
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="text-center" justify="center" no-gutters>
          <v-col cols="auto">
            <v-btn
              class="default-btn primary mx-2"
              @click="clearForm()"
            >
              Limpiar
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              class="default-btn primary mx-2"
              :loading='searchLoader'
              :disabled="!searchDisabled"
              @click="getFilters()"
            >
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { contractFilters } from '@/utils/contractFilters'
/* Bus */
import Bus from '@/helpers/bus'

export default {
  name: 'ContractTemplateFilters',
  props: {
    countryFilter: {
      type: Boolean,
      default: () => false
    }
  },

  data () {
    return {
      menu: false,
      panel: [0],
      form: {
        search: null,
        dates: [],
        typeTemplate: null,
        country: null,
        folder: null,
        name: null
      },
      searchLoader: false,
      searchDisabled: false
    }
  },

  watch: {
    form: {
      handler (val) {
        // this.$emit('filters', val)
        this.validateDisabledSearch(val)
      },
      deep: true
    }
  },

  created () {
    Bus.$on('filter-clear-all', () => {
      this.clearForm()
    })
  },

  computed: {
    ...mapState(['countries', 'templateTypes', 'topFolders']),

    dateRangeText: {
      get () {
        return this.form.dates.join(' ~ ')
      },
      set (value) {
        this.form.dates = []
      }
    }
  },

  methods: {
    ...mapActions('contract', ['getFilterDocuments']),
    ...mapMutations('contract', { setContracts: 'SET_CONTRACTS', setLevelTopFolders: 'SET_LEVEL_TOP_FOLDERS' }),
    ...mapMutations({ setFolderSelection: 'SET_FOLDER_SELECTION' }),

    getFilters () {
      this.search = this.form.search
      const params = this.getQueryParams(this.form)
      if (params.dates) {
        params.start_date = params.dates[0]
        params.end_date = params.dates[1]
        delete params.dates
      }

      this.searchLoader = true
      this.getFilterDocuments(params)
        .then(response => {
          Bus.$emit('navegation-clear')
          this.setLevelTopFolders(0)
          this.setContracts(response.data.contracts)
          this.searchLoader = false
        })
    },

    /* Return list queryParams */
    getQueryParams (data) {
      const params = {}
      for (const key in data) {
        const element = data[key]
        if (key === 'dates') {
          const len = element.length
          if (len === 2) {
            params[key] = element
          }
        } else {
          if (element && key !== 'search') {
            const query = contractFilters[key]
            params[query] = element
          }
        }
      }
      return params
    },

    validateDisabledSearch (val) {
      this.searchDisabled = val.typeTemplate !== null || val.name !== null || val.country !== null || val.dates.length > 0
    },

    clearForm () {
      this.form = {
        search: null,
        dates: [],
        typeTemplate: null,
        country: null,
        folder: null,
        name: null
      }
    }
  }
}
</script>

<style lang="scss">

.filters-container {
  .v-expansion-panel {
    background: var(--v-background-base) !important;
    padding-right: 14px;
  }

  .filters-header {
    font-weight: 500;
    color: black;
    padding-top: 0;
  }

  .v-expansion-panel-content__wrap {
    padding: 0;
  }

  .v-text-field .v-input__control .v-input__slot {
    height: 45px;
    min-height: 100%;
    border-radius: 6px;
  }

  .filter-label {
    color: var(--v-dark-base);
  }

  .mdi-close-circle {
    color: var(--v-dark-base);
  }

  .mdi-menu-up {
    color: var(--v-dark-base);
  }

  .mdi-menu-down {
    color: var(--v-dark-base) !important;
  }
}

@media screen and (max-width: 1380px) {
  .filters-container {
    .v-expansion-panel {
      padding: 0;
    }

    .v-text-field .v-input__control .v-input__slot {
      font-size: 14px;
    }

    .filter-label {
      color: var(--v-dark-base);
      font-size: 15px;
    }

    .mdi-close-circle {
      color: var(--v-dark-base);
    }
  }
}
</style>
