<template>
  <div class="contracts-container fill-height" v-if="!isLoading && contracts">
    <!-- Content -->
        <v-row no-gutters justify="space-between" align="center">
        <v-col cols="auto">
          <v-row>
            <v-col>
              <span class="section-text">Contratos</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto" v-if="!isClientProfile()">
          <ContractGenerationDialog>
            <template slot="text">
              Generar Contrato
            </template>
          </ContractGenerationDialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-row no-gutters>
            <v-col>
              <div class="filters">
                <ContractFilters :country-filter="isLegalProfile()"/>
              </div>
              <div class="folders">
                <ContractFolders/>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <!--[><ContractFolders /><]-->
        <v-col v-if="topFolders.folders.length === 0 && topFolders.contracts.length === 0" class="no-contracts-container">
          <div class="topFolders">
            <TopFolders></TopFolders>
          </div>
          <v-row align="center" justify="center" class="fill-height">
            <v-col cols="auto">
              <v-row align="center" justify="center" class="no-content-description">
                <v-col cols="auto">
                  <div class="image-container">
                    <img
                      src="@/assets/images/empty-contracts.png"
                      alt="Addiuva"
                    >
                  </div>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="auto">
                  <p class="no-content-text text-center">
                    Aún no tienes contratos,
                  </p>
                  <p class="text-center no-content-text">
                    Inicia el proceso de creación.
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-else cols="12" md="9" sm="12">
          <div class="topFolders">
            <TopFolders></TopFolders>
          </div>
          <div v-if="isIteratorLoading">
            <Loader type="single" />
          </div>
          <div v-else-if="contracts.length > 0">
            <v-data-iterator
              :items="contracts"
              :search="search"
              loading-text="Cargando"
              :items-per-page="9"
              :footer-props="{itemsPerPageOptions:[9, 18, 27, -1]}"
            >
              <template v-slot:default="props">
                <v-row>
                  <v-col class="contract-card-col" cols="12" sm="6" md="4" v-for="contract in props.items" :key="contract.iddocument">
                    <ContractCard :contract="contract"></ContractCard>
                  </v-col>
                </v-row>
              </template>
          </v-data-iterator>
          </div>
        </v-col>
      </v-row>
  </div>
  <div v-else class="loading-container">
    <Loader />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

/* Components */
import ContractFilters from './Filters'
import ContractCard from './Card'
import ContractFolders from './Folders'
import TopFolders from './TopFolders'
import ContractGenerationDialog from './GenerationDialog'
import Loader from '@/components/Base/Loader'

/* Mixins */
import permissionValidate from '@/mixins/permissionValidate'
/* Bus */
import Bus from '@/helpers/bus'

export default {
  name: 'Contract',
  components: {
    ContractFilters,
    ContractGenerationDialog,
    ContractCard,
    Loader,
    ContractFolders,
    TopFolders
  },
  mixins: [permissionValidate],

  data () {
    return {
      search: '',
      isLoading: false,
      isIteratorLoading: false
    }
  },
  mounted () {
    Bus.$on('update-contracts-socket', () => {
      this.obtainDocuments()
    })
  },

  computed: {
    ...mapState('contract', ['contracts', 'topFolders', 'goBack']),
    ...mapState(['countries'])
  },

  created () {
    this.obtainDocuments()
  },

  methods: {
    ...mapActions('contract', ['getDocuments']),
    obtainDocuments () {
      // Validación que permite volver a las carpetas seleccionadas cuando se abre un contrato
      if (this.goBack.level > 0) {
        if (this.goBack.goBackNav) {
          let params = {}
          params = {
            country: this.goBack.country,
            level: this.goBack.level,
            goBackNav: this.goBack.goBackNav
          }
          if (this.goBack.level === 1) params.folder = this.goBack.country
          if (this.goBack.level === 2 || this.goBack.level === 3) params.folder = this.goBack.folder
          if (this.goBack.level === 3) params.type_template = this.goBack.type_template
          this.getDocuments(params)
            .then(() => {
              this.isLoading = false
            })
            .catch(() => {
              this.isLoading = false
            })
            .finally(() => {
              Bus.$emit('navegation-go-back-update-contract')
            })
        }
      } else {
        this.isLoading = true
        this.getDocuments()
          .then(() => {
            Bus.$emit('navegation-clear')
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    }
  }
}
</script>

<style lang="scss">

.contracts-container {
  padding: 26px 43px;

  .no-contracts-container {
    height: 100%;
  }

  .image-container {
    background: rgba(185, 195, 235, .2);
    height: 58px;
    border-radius: 8px;
    img {
      margin: 5px;
    }
  }

  .section-text {
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    color: black;
  }

  .no-content-text {
    margin: 0;
    padding: 0;
    color: gray;
  }
}

</style>
