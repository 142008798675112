<template>
<div>
  <v-expansion-panels
    v-model="panel"
    class="folders-container"
    flat
    multiple
  >
    <v-expansion-panel>
      <v-expansion-panel-header class="folders-header px-0">Carpetas</v-expansion-panel-header>
      <v-expansion-panel-content max-length="100px">
        <v-text-field solo flat outlined dense clear-icon="mdi-close-circle" label="Buscar ..." clearable v-model="foderSearch"></v-text-field>
        <v-btn
          v-if="!isClientProfile()"
          class="noUppercase"
          text
          @click="createDialog = true"
        >
          <v-icon class="pr-2" color="primary">mdi-folder-plus</v-icon>
          Crear carpeta
        </v-btn>
        <v-treeview
          class="scroll"
          v-model="tree"
          :open="initiallyOpen"
          :items="contractLeftFolders"
          activatable
          item-key="id"
          return-object
          open-on-click
          :search="foderSearch"
          :active="treeViewActive"
          @update:active=" treeViewActive = []"
          >
            <template v-slot:prepend="{ open }">
              <v-icon color="primary">
                {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
              </v-icon>
            </template>
            <template v-slot:label="{ item }">
              <label class="folder-label" @click="getNavegationFromFolders(item)">{{ item.name }}</label>
            </template>
        </v-treeview>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  <ConfirmDialog
    functionToCall='saveFolder'
    :dialog="createDialog"
    :formValid="folderFormValid"
    :buttonLoader="createButtonLoader"
    @close="closeDialog"
    @saveFolder="saveFolder">
    <template slot='content'>
      <h4 class="pa-8 centerH4">Crear carpeta</h4>
      <v-form ref="folderFormValidRef" v-model="folderFormValid">
        <v-row>
          <v-col cols="12" md="12" class="pl-16 pr-16">
            <h5 class="mb-2 text-color"> Nombre de la carpeta </h5>
            <v-text-field
              class="dialogFields"
              v-model="newFolderName"
              :rules="folderNameRules"
              label="Escribe el nombre de la carpeta"
              outlined
              single-line
              background-color="white"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="12" md="12" class="pl-16 pr-16">
            <h5 class="mb-2 text-color"> Elige el país </h5>
            <v-select
              :items="countries"
              item-text="name"
              item-value="country_code"
              :rules="countryRules"
              label="País"
              solo
              outlined
              clearable
              dense
              clear-icon="mdi-close-circle"
              v-model="folderCountry"
              return-object
              flat
              :disabled="!this.isLegalProfile()"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </ConfirmDialog>
</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
/* Bus */
import Bus from '@/helpers/bus'
import ConfirmDialog from '../Base/ConfirmDialog'
import permissionValidate from '@/mixins/permissionValidate'
import { colors } from '@/utils/colors'

export default {
  name: 'ContractFolders',
  components: { ConfirmDialog },
  mixins: [permissionValidate],
  data: () => ({
    menu: false,
    panel: [0],
    initiallyOpen: [''],
    tree: [],
    foderSearch: '',
    createDialog: false,
    newFolderName: '',
    folderCountry: '',
    folderNameRules: [
      v => !!v || 'El nombre es requerido'
    ],
    countryRules: [
      v => !!v || 'El país es requerido'
    ],
    folderFormValid: false,
    treeViewActive: [],
    createButtonLoader: false
  }),
  watch: {
  },

  created () {
    this.getContractLeftFolders()
    this.folderCountry = this.countries.find((item) => {
      return item.country_code === localStorage.getItem('country')
    })

    Bus.$on('refresh-left-folders', () => {
      this.getContractLeftFolders()
      this.getDocuments()
      Bus.$emit('navegation-clear')
    })
  },

  computed: {
    ...mapState('contract', ['folders']),
    ...mapState(['countries', 'templateTypes', 'contractLeftFolders'])
  },

  methods: {
    ...mapActions('contract', ['newFolder', 'getDocuments']),
    ...mapActions(['getContractLeftFolders']),
    ...mapMutations('contract', { setLevelTopFolders: 'SET_LEVEL_TOP_FOLDERS' }),
    ...mapMutations({ setSnackbar: 'SET_SNACKBAR' }),

    getNavegationFromFolders (item) {
      const navegationInfo = []
      this.setLevelTopFolders(item.level)
      switch (item.level) {
        case 1:
          Bus.$emit('navegation-update', navegationInfo, item)
          break

        case 2:
          this.contractLeftFolders.forEach(country => {
            if (country.country === item.country) {
              navegationInfo.push(country)
              Bus.$emit('navegation-update', navegationInfo, item)
            }
          })
          break

        case 3:
          this.contractLeftFolders.forEach(country => {
            if (country.country === item.country) {
              country.children.forEach(children => {
                if (children.identifier === item.identifier) {
                  navegationInfo.push(country)
                  navegationInfo.push(children)
                  Bus.$emit('navegation-update', navegationInfo, item)
                }
              })
            }
          })
          break
      }
    },

    saveFolder () {
      const userId = localStorage.getItem('user')
      const body = {
        name: this.newFolderName,
        folderCountry: this.folderCountry.idcountry,
        user: userId
      }
      this.createButtonLoader = true
      this.newFolder(body)
        .then(() => {
          this.getDocuments()
          this.getContractLeftFolders()
          this.createButtonLoader = false
          // Bus.$emit('country-cleared')
          this.closeDialog()
          this.setSnackbar({
            text: 'La carpeta ha sido creada',
            timeout: 5000,
            showing: true,
            color: colors.primary
          })
        })
        .catch(() => {
          this.createButtonLoader = false
        })
    },

    closeDialog () {
      this.createDialog = false
      this.$refs.folderFormValidRef.resetValidation()
      this.newFolderName = ''
      this.folderCountry = ''
    }
  }
}
</script>
<style lang="scss">

.text-color {
  color: var(--v-dark-base);
}

.centerH4 {
  justify-content: center;
}

.folder-label {
    text-transform: none !important;
    width: 100%;
    display: flex;
    justify-content: left;
    cursor: pointer !important;
}

.scroll {
  max-height: 200px;
  overflow-y: scroll;
}

.folders-container {
  .v-expansion-panel {
    background: var(--v-background-base) !important;
  }

  .folders-header {
    font-weight: 500;
    color: black;
    padding-top: 0;
  }

  .v-expansion-panel-content__wrap {
    padding: 0;
  }

  .v-text-field .v-input__control .v-input__slot {
    height: 45px;
    min-height: 100%;
    border-radius: 6px;
  }

  .filter-label {
    color: var(--v-dark-base);
  }

  .mdi-close-circle {
    color: var(--v-dark-base);
  }

  .mdi-menu-up {
    color: var(--v-dark-base);
  }

  .mdi-menu-down {
    color: var(--v-dark-base) !important;
  }
}

@media screen and (max-width: 1380px) {
  .folders-container {
    .v-expansion-panel {
      padding: 0;
    }

    .v-text-field .v-input__control .v-input__slot {
      font-size: 14px;
    }

    .filter-label {
      color: var(--v-dark-base);
      font-size: 15px;
    }

    .mdi-close-circle {
      color: var(--v-dark-base);
    }
  }
}

.noUppercase {
    background-color: none !important;
    text-transform: none !important;
}
</style>
