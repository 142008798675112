<template>
<div>
  <v-card
    outlined
    class="contract-card"
    exact
    @click="setDetailContractInfo"
  >
    <div class="mt-4 mb-0" v-if="contract.idstatus === 4">
      <v-row :class="colorClass.color" no-gutters align="center">
        <v-col cols="auto" :class="colorClass.strong">
        </v-col>
        <v-col :class="colorClass.ligth">
          <div class="expiration-span"> {{ colorClass.text }} </div>
        </v-col>
      </v-row>
    </div>
    <div class="mt-4 mb-0" v-else>
      <v-row class="expiration-text" no-gutters align="center">
        <v-col cols="auto" class="first">
        </v-col>
        <v-col class="second">
          <h5 class="expiration-span"> El contrato no tiene fechas configuradas</h5>
        </v-col>
      </v-row>
    </div>
    <div class="pa-5">
      <v-row no-gutters justify="space-between">
        <v-col cols="auto">
          <p class="name">{{ contract.title }} - {{ contract.country }}</p>
        </v-col>
        <v-col cols="auto">
          <v-menu>
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <v-icon
                  style="vertical-align: text-bottom;"
                  color="primary"
                  @click="shareDialog = true"
                  v-bind="attrs"
                  v-on:click.prevent
                  v-on="{ ...tooltip, ...menu }"
                >
                  mdi-dots-vertical
                </v-icon>
                </template>
                <span>Opciones</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item class="cursor-pointer">
                <v-list-item-title @click="getFolderToMoveList()"> Mover </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="profile==='Legal'" class="cursor-pointer">
                <v-list-item-title @click="showDeleteDocumentDialog=true"> Eliminar </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <p class="description">{{ description }}</p>
      <p class="date">Fecha de creación: {{ contract.created }}</p>
      <p class="date mb-2">Tipo: {{ contract.templateType }}</p>
      <p class="date mb-2" v-if="contract.client">Carpeta: {{ contract.client }}</p>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row no-gutters justify="space-between" align="center">
          <v-col cols="auto">
            <span class="state">
              Estado: {{contract.status}}
            </span>
          </v-col>
          <v-col cols="auto">
            <v-icon class="sm" color="primary">{{ contract.icon }}</v-icon>
          </v-col>
        </v-row>
      </v-card-actions>
    </div>
  </v-card>
  <!-- move folder dialog -->
  <v-dialog
      v-model="showMoveFolderDialog"
      max-width="600"
      @click:outside="() => {showMoveFolderDialog = false}"
      @keydown.esc="() => {showMoveFolderDialog = false}"
    >
      <v-card class="pa-4" max-width="auto" height="auto">
        <v-card-title class="center centered-dialog__card">
          <h3> Cambiar carpeta del contrato </h3>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-select
            :items="folderToMoveList"
            class="mt-6"
            item-text="name"
            item-value="id"
            label="Seleccione la carpeta"
            solo
            outlined
            clearable
            dense
            clear-icon="mdi-close-circle"
            v-model="folderToMove"
            no-data-text="Aún no hay carpetas creadas"
            flat
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-row align="center" justify="center">
            <v-col cols="auto">
              <v-btn
                depressed
                color="default-btn primary"
                @click="showMoveFolderDialog = false"
              >
                Cancelar
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                depressed
                color="primary default-btn"
                :loading="submitFolderLoader"
                @click="submitMoveContract()"
              >
                Aceptar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
  </v-dialog>
  <!-- delete document dialog -->
  <v-dialog
      v-model="showDeleteDocumentDialog"
      max-width="600"
      @click:outside="() => {showDeleteDocumentDialog = false}"
      @keydown.esc="() => {showDeleteDocumentDialog = false}"
    >
      <v-card class="pa-4" max-width="auto" height="auto">
        <v-card-title class="center centered-dialog__card">
          <h3> Eliminar contrato </h3>
        </v-card-title>
        <v-card-text class="pb-0">
          Al eliminar el contrato <b> {{ contract.title }} </b> ya no podrá recuperarlo
        </v-card-text>
        <v-card-actions>
          <v-row align="center" justify="center">
            <v-col cols="auto">
              <v-btn
                depressed
                color="default-btn"
                @click="showDeleteDocumentDialog = false"
              >
                Cancelar
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                depressed
                color="primary default-btn"
                :loading="submitDeleteLoader"
                @click="submitDeleteContract()"
              >
                Eliminar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
  </v-dialog>
</div>
</template>

<script>
import moment from 'moment'
/* Mixins */
import expirationColor from '@/mixins/expirationColor'
import { mapMutations, mapState, mapActions } from 'vuex'
import { colors } from '@/utils/colors'

/* Bus */
import Bus from '@/helpers/bus'

export default {
  name: 'ContractCard',

  mixins: [expirationColor],

  props: {
    contract: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    today: moment().format('YYYY/MM/DD'),
    endDate: '',
    reminderDate: '',
    colorClass: '',
    userProfile: '',
    showMoveFolderDialog: false,
    showDeleteDocumentDialog: false,
    folderToMoveList: [],
    folderToMove: '',
    submitFolderLoader: false,
    submitDeleteLoader: false

  }),

  created () {
    this.userProfile = localStorage.getItem('profile')
    if (this.contract.idstatus === 4) {
      this.endDate = moment(this.contract.endDate).format('YYYY/MM/DD')
      this.reminderDate = moment(this.contract.reminderDate).format('YYYY/MM/DD')
      this.whichColorIsThis()
    }
  },

  computed: {
    ...mapState(['contractLeftFolders']),
    description () {
      return `Creado por ${this.contract.userCreated}, ${this.contract.group}`
    },
    profile () { return this.$store.state.profile }
  },

  methods: {
    ...mapMutations('contract', {
      setDetailContract: 'SET_DETAIL_CONTRACT'
    }),
    ...mapMutations({ setSnackbar: 'SET_SNACKBAR' }),
    ...mapActions('contract', ['moveContract', 'removeDocument']),

    setDetailContractInfo () {
      this.setDetailContract(this.contract)
      this.$router.push(`/portal/contracts/${this.contract.iddocument}`)
    },

    getFolderToMoveList () {
      this.showMoveFolderDialog = true
      this.folderToMoveList = this.contractLeftFolders.filter(item => item.country === this.contract.country)[0].children
    },

    async submitDeleteContract () {
      this.submitDeleteLoader = true
      try {
        await this.removeDocument(this.contract.iddocument)
        Bus.$emit('submit-remove-document')
        this.setSnackbar({
          text: 'Se ha eliminado el contrato',
          timeout: 5000,
          showing: true,
          color: colors.primary
        })
      } catch (error) {
        console.error(`Card.submitDeleteContract error: ${error}`)
        this.setSnackbar({
          text: 'No se pudo realizar la acción',
          timeout: 5000,
          showing: true,
          color: colors.error
        })
      } finally {
        this.submitDeleteLoader = false
      }
    },

    submitMoveContract () {
      let request = {}
      if (this.folderToMove) {
        request = {
          document: this.contract.iddocument,
          newFolder: this.folderToMove
        }
      } else {
        request = {
          document: this.contract.iddocument
        }
      }
      this.submitFolderLoader = true
      this.moveContract(request)
        .then(response => {
          this.submitFolderLoader = false
          Bus.$emit('submit-move-folder')
          this.setSnackbar({
            text: response.data.detail,
            timeout: 5000,
            showing: true,
            color: colors.success
          })
        })
        .catch(() => {
          this.submitFolderLoader = false
          this.setSnackbar({
            text: 'No se pudo realizar la acción',
            timeout: 5000,
            showing: true,
            color: colors.success
          })
        })
    }
  }

}
</script>

<style lang="scss">
@import '../../assets/css/expirationCard';

.contract-card:hover {
  border: 1px solid var(--v-primary-base);
}

@media screen and (max-width: 1700px) {
  .contract-card {
    height: auto;
    padding: 0;
    .name {
      font-size: 14px;
    }

    .description {
      font-size: 12px;
    }

    .date {
      font-weight: normal;
      font-size: 12px;
      color: gray;
      margin: 0 0 !important;
    }
  }
}
</style>
