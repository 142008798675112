<template>
  <div v-if="!isLoading">
    <div v-if="isFolderSelected">
      <v-row>
        <v-col cols="auto">
          <span class="pointer" @click="goIndex()"> Mis carpetas </span>
        </v-col>
        <v-col cols="auto">
          <span> > </span>
        </v-col>
        <!-- <v-col cols="auto" v-for="(nav, key) in navegation" :key="key">
          <span> {{ nav.name }} - {{ nav.country }} </span>
          <span> {{ folderSelected.name }} - {{ folderSelected.country }}</span>
        </v-col> -->
      </v-row>
    </div>
    <div v-else>
      <v-row>
        <v-col cols="auto">
          <span class="pointer" @click="goIndex()"> Mis carpetas </span>
        </v-col>
        <div style="display: flex;" v-for="(nav, key) in navegation" :key="key">
          <v-col cols="auto" v-if="topFolders.level > 1">
            <span> > </span>
          </v-col>
          <v-col cols="auto">
            <span class="pointer" @click="folderGoBack(nav)"> {{ nav.name }} </span>
          </v-col>
        </div>
      </v-row>
      <v-data-iterator
        :class="showScroll()"
        :items="topFolders.folders"
        hide-default-footer
        no-data-text=""
      >
        <template v-slot:default="props">
          <v-row v-if="topFolders.level === 1">
            <v-col class="contract-card-col" cols="12" sm="6" md="4" v-for="folder in props.items" :key="folder.iddocument">
              <v-btn
                class="topBtns text-truncate"
                text
                outlined
                @click="getContractsFromLevel(folder)"
              >
                <v-icon class="pr-2" color="primary">mdi-folder</v-icon>
                <span>{{folder.name}}</span>
                <v-spacer></v-spacer>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="topFolders.level === 2">
            <v-col class="contract-card-col" cols="12" sm="6" md="4" v-for="folder in props.items" :key="folder.iddocument">
              <v-btn
                class="topBtns text-truncate"
                text
                outlined
                @click="getContractsFromLevel(folder)"
              >
                <v-icon class="pr-2" color="primary">mdi-folder</v-icon>
                <span>{{folder.name}} - {{folder.country}}</span>
                <v-spacer></v-spacer>
                <v-menu v-if="profile==='Legal'">
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-icon
                        style="vertical-align: text-bottom;"
                        color="primary"
                        @click="shareDialog = true"
                        v-bind="attrs"
                        v-on:click.prevent
                        v-on="{ ...tooltip, ...menu }"
                      >
                        mdi-dots-vertical
                      </v-icon>
                      </template>
                      <span>Opciones</span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-list-item class="cursor-pointer">
                      <v-list-item-title @click="openDeleteFolderDialog(folder)"> Eliminar </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="topFolders.level === 3">
            <v-col class="contract-card-col" cols="12" sm="6" md="4" v-for="folder in props.items" :key="folder.iddocument">
              <v-btn
                class="topBtns text-truncate"
                text
                outlined
                @click="getContractsFromLevel(folder)"
              >
                <v-icon class="pr-2" color="primary">mdi-folder</v-icon>
                <span>{{folder.name}} - {{folder.country}}</span>
                <v-spacer></v-spacer>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </div>
    <!-- delete document dialog -->
    <v-dialog
        v-model="showDeleteFolderDialog"
        max-width="600"
        @click:outside="() => {showDeleteFolderDialog = false}"
        @keydown.esc="() => {showDeleteFolderDialog = false}"
      >
        <v-card class="pa-4" max-width="auto" height="auto">
          <v-card-title class="center centered-dialog__card">
            <h3> Eliminar carpeta </h3>
          </v-card-title>
          <v-card-text class="pb-0" v-if="selectedFolder">
            Al eliminar la carpeta <b> {{ selectedFolder.name }} </b> tambien se eliminarán los documentos que la componen y no se podrán recuperar nuevamente.
          </v-card-text>
          <v-card-actions>
            <v-row align="center" justify="center">
              <v-col cols="auto">
                <v-btn
                  depressed
                  color="default-btn"
                  @click="showDeleteFolderDialog = false"
                >
                  Cancelar
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  depressed
                  color="primary default-btn"
                  :loading="loadingDeleteFolder"
                  @click="submitDeleteFolder()"
                >
                  Eliminar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
  <div v-else class="loading-container">
    <Loader />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Loader from '@/components/Base/Loader'
import { colors } from '@/utils/colors'

/* Bus */
import Bus from '@/helpers/bus'

export default {
  name: 'TopFolders',
  components: {
    Loader
  },
  data: () => ({
    items: [],
    foderSearch: '',
    isFolderSelected: false,
    folderSelected: {},
    clientFolderSelected: {},
    navegation: [],
    isLoading: false,
    scrollClass: 'scroll',
    loadingDeleteFolder: false,
    showDeleteFolderDialog: false,
    selectedFolder: null
  }),
  watch: {
    topFolders: {
      handler (val) {
      },
      deep: true
    },
    goBack: {
      handler (val) {
      },
      deep: true
    },
    navegation: {
      handler (val) {
      },
      deep: true
    }
  },
  created () {
    Bus.$on('folder-selected', (folderSelected) => {
      this.folderSelected = folderSelected
      this.isFolderSelected = true
    })

    Bus.$on('navegation-update', (navegationInfo, item) => {
      this.navegation = Array.from(new Set(navegationInfo.map(JSON.stringify))).map(JSON.parse)
      if (this.navegation[1]) {
        this.clientFolderSelected = this.navegation[1]
      }
      this.getContractsFromLevel(item)
    })

    // Bus para actualizar la barra de navegación y obtener las carpetas previamente seleccionadas
    Bus.$on('navegation-go-back-update-contract', () => {
      this.navegation = this.goBack.goBackNav
      this.isFolderSelected = false
    })

    Bus.$on('navegation-clear', () => {
      this.navegation = []
      this.setLevelTopFolders(1)
      this.setGoBack({
        country: '',
        level: 0,
        folder: '',
        goBackNav: null
      })
    })

    Bus.$on('submit-move-folder', () => {
      this.goIndex()
    })

    Bus.$on('submit-remove-document', () => {
      this.goIndex()
    })
  },
  computed: {
    ...mapState('contract', ['topFolders', 'goBack']),
    ...mapState(['folderSelection']),
    profile () { return this.$store.state.profile }
  },

  methods: {
    ...mapMutations({
      setFolderSelection: 'SET_FOLDER_SELECTION'
    }),
    ...mapMutations('contract', { setLevelTopFolders: 'SET_LEVEL_TOP_FOLDERS', setGoBack: 'SET_GO_BACK' }),
    ...mapMutations({ setSnackbar: 'SET_SNACKBAR' }),
    ...mapActions('contract', ['getDocuments', 'removeFolder']),

    goIndex () {
      this.isFolderSelected = false
      Bus.$emit('filter-clear-all')
      this.setFolderSelection({})
      this.isLoading = true
      this.getDocuments()
        .then(() => {
        })
        .catch(() => {
        })
        .finally(() => {
          this.isLoading = false
        })
      Bus.$emit('navegation-clear')
    },

    getContractsFromLevel (item) {
      Bus.$emit('filter-clear-all')
      let params = {}
      if (this.topFolders.level === 1) {
        item.level = 1
        this.navegation[0] = item
        params = {
          country: item.country,
          level: this.topFolders.level,
          folder: item.country,
          goBackNav: this.navegation
        }
        this.isLoading = true
        this.getDocuments(params)
          .then(() => {
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
          })
      } else if (this.topFolders.level === 2 && Number(item.id)) {
        item.level = 2
        this.navegation[1] = item
        this.clientFolderSelected = item
        params = {
          country: item.country,
          folder: item.id,
          level: this.topFolders.level,
          goBackNav: this.navegation
        }
        this.isLoading = true
        this.getDocuments(params)
          .then(() => {
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
          })
      } else if (this.topFolders.level === 3) {
        item.level = 3
        this.navegation[2] = item
        if (typeof (item.id) === 'string') {
          item.id = parseInt(item.id.split('-')[1])
        }
        params = {
          country: item.country,
          type_template: item.id,
          folder: this.clientFolderSelected.id,
          level: this.topFolders.level,
          goBackNav: this.navegation
        }
        this.isLoading = true
        this.getDocuments(params)
          .then(() => {
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
          })
      }
    },

    folderGoBack (navItem) {
      this.setLevelTopFolders(navItem.level)
      this.navegation = this.navegation.slice(0, navItem.level)
      this.getContractsFromLevel(navItem)
    },

    showScroll () {
      if (this.topFolders) {
        if (this.topFolders.contracts.length > 0) {
          return 'scroll'
        } else {
          return 'noScroll'
        }
      } else {
        return 'noScroll'
      }
    },

    openDeleteFolderDialog (selectedFolder) {
      this.showDeleteFolderDialog = true
      this.selectedFolder = selectedFolder
    },

    async submitDeleteFolder () {
      this.loadingDeleteFolder = true
      try {
        await this.removeFolder(this.selectedFolder.id)
        this.showDeleteFolderDialog = false
        Bus.$emit('submit-move-folder')
        this.setSnackbar({
          text: 'Se ha eliminado la carpeta',
          timeout: 5000,
          showing: true,
          color: colors.primary
        })
        this.selectedFolder = null
      } catch (error) {
        console.error(`TopFolders.submitDeleteFolder error: ${error}`)
        this.setSnackbar({
          text: 'No se pudo realizar la acción',
          timeout: 5000,
          showing: true,
          color: colors.error
        })
      } finally {
        this.loadingDeleteFolder = false
      }
    }
  }
}
</script>
<style lang="scss">

.scroll {
  max-height: 130px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.noScroll {
  height: auto;
  max-height: 800px;
}

.topBtns {
    padding-right: 0 !important;
    text-transform: none !important;
    width: 100%;
    display: flex;
    justify-content: left;
    background-color: white;
    border-radius: 5px;
}
.iconPadding{
  padding-right: 10px;
  margin-left: 10px;
}
.pointer {
  cursor: pointer;
}
</style>
